@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: swing 10s infinite ease;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes swing
{
  0%{ transform: rotate(-10deg);}
  50%{ transform: rotate(20deg);}
  100%{ transform: rotate(-10deg);}
}
